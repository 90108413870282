export const statuses = {
  available: 'Disponível',
  maintenance: 'Em manutenção',
  in_use: 'Em uso',
  cleaning: 'Limpeza e higienização',
};

export function getName(value) {
  return statuses[value];
}
