<template>
  <div class="page-container bed-management-list-page">
    <portal to="page-name">Gerenciamento de leitos</portal>
    <div class="card card-page">
      <div class="card-header">
        <h1 class="card-title">Gerenciamento de leitos</h1>
      </div>
      <div class="card-body">
        <div class="loading mt-2" v-if="loading"></div>
        <template v-else>
          <template v-if="data.items.length === 0">
            <div class="empty mt-2">
              <div class="empty-icon">
                <fa-icon :icon="['fal', 'info-circle']" size="3x"/>
              </div>
              <p class="empty-title h5">Leitos</p>
              <p class="empty-subtitle">
                Nenhum leito cadastrado.
              </p>
            </div>
          </template>
          <template v-else>
            <div v-for="(place, i) in data.items" :key="i">
              <div class="place">{{ place.name }}</div>
              <table class="table">
                <thead>
                <tr>
                  <th style="width:40%">Leito</th>
                  <th style="width:20%">Status</th>
                  <th style="width:40%">Paciente</th>
                  <th></th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="(bed, n) in place.beds" :key="n">
                  <td>
                    <span class="hover-link" @click="edit(bed.id)">
                      {{ bed.name }} ({{ bed.accommodationType.name }})
                    </span>
                  </td>
                  <td>{{ getStatusName(bed.status) }}</td>
                  <td>{{ bed.entity ? bed.entity.name : '-' }}</td>
                  <td class="text-right">
                    <button class="btn btn-sm btn-action btn-icon btn-gray"
                            @click="edit(bed.id)">
                      <fa-icon :icon="['fal', 'pencil']"></fa-icon>
                    </button>
                  </td>
                </tr>
                </tbody>
              </table>
            </div>
          </template>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import * as placeType from 'src/data/place-types';
import * as bedStatuses from 'src/data/bed-statuses';

export default {
  data() {
    return {
      debounceTimeout: null,
      loading: false,
      placeType,
      bedStatuses,
      filter: {
        search: '',
      },
      data: {
        items: [],
        total: 0,
        limit: 30,
        offset: 0,
      },
    };
  },
  watch: {
    filter: {
      handler() {
        clearTimeout(this.debounceTimeout);
        this.debounceTimeout = setTimeout(this.search, 600);
      },
      deep: true,
    },
  },
  mounted() {
    this.load();
  },
  methods: {
    search() {
      this.data.offset = 0;
      this.load();
    },
    getStatusName(status) {
      return bedStatuses.getName(status);
    },
    load() {
      this.loading = true;

      const params = {
        limit: this.data.limit,
        offset: this.data.offset,
        type: 'hospitalization',
        active: true,
      };

      if (this.filter.search.trim().length > 0) {
        params.search = this.filter.search;
      }

      this.$http.get('/places', { params })
        .then(({ data }) => {
          this.data = data;
        })
        .catch(() => {})
        .then(() => {
          this.loading = false;
        });
    },
    edit(id) {
      this.$router.push(`/bed-management/${id}/edit`);
    },
  },
};
</script>

<style lang="scss">
  @import "./src/assets/scss/_variables.scss";
  .bed-management-list-page {
    .place {
      background-color: $gray-color-light;
      font-weight: bold;
      padding: $layout-spacing;
    }
  }
</style>
