<template>
  <div class="page-container bed-management-page">
    <portal to="page-name">Gerenciamento de leito</portal>
    <div class="card card-page">
      <div class="card-header">
        <h1 class="card-title">Gerenciamento de leito</h1>
      </div>
      <div class="card-body">
        <div class="columns form-group">
          <div class="column col-6 form-group">
            <label for="name" class="form-label">Leito</label>
            <input type="text" id="name" name="name" readonly
                   v-model="form.name" class="form-input">
          </div>
          <div class="column col-6 form-group">
            <label for="accommodation" class="form-label">Acomodação</label>
            <input type="text" id="accommodation" name="name" readonly
                   v-model="form.accommodationType.name" class="form-input">
          </div>
          <div class="column col-6 form-group">
            <label for="patient-name" class="form-label">Paciente</label>
            <input type="text" id="patient-name" name="name" readonly
                   v-model="form.entity.name" class="form-input">
          </div>
          <div class="column col-6 col-sm-12 form-group">
            <label for="status" class="form-label">Status</label>
            <select id="status" class="form-select"
                    v-model="form.status" :disabled="!!form.entity.id">
              <option value="">[Selecione]</option>
              <option v-for="(text, value) in bedStatuses.statuses"
                      :value="value" :key="value">{{ text }}</option>
            </select>
          </div>
        </div>
      </div>
      <div class="card-footer">
        <button class="btn btn-primary mr-1" @click="save()"
                :disabled="saving" :class="{loading: saving}">
          Salvar
        </button>
        <button class="btn" @click="$router.back()">
          Voltar
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { required } from 'vuelidate/src/validators';
import { mergeFrom } from '@/helpers/object';
import formMixin from 'src/mixins/form';
import * as bedStatuses from 'src/data/bed-statuses';

export default {
  mixins: [formMixin],
  data() {
    return {
      path: '/beds',
      loading: false,
      saving: false,
      bedStatuses,
      form: this.blankForm(),
    };
  },
  async mounted() {
    this.load();
  },
  validations() {
    return {
      form: {
        status: { required },
      },
    };
  },
  methods: {
    load() {
      this.loading = true;

      return this.$http
        .get(`${this.path}/${this.$route.params.id}`)
        .then(({ data }) => {
          this.form = mergeFrom(this.blankForm(), data);
        })
        .catch(() => {})
        .then(() => {
          this.loading = false;
        });
    },
    save() {
      this.$v.form.$touch();
      if (this.$v.form.$error) {
        return;
      }

      this.saving = true;

      const bedData = {
        status: this.form.status,
      };

      this.$http
        .put(`/beds/${this.form.id}`, bedData)
        .then(() => {
          this.$toast.show('Registro salvo');
        })
        .catch(() => {})
        .then(() => {
          this.saving = false;
        });
    },
    blankForm() {
      return {
        id: '',
        name: '',
        status: '',
        accommodationType: {
          id: '',
          name: '',
        },
        entity: {
          id: '',
          name: '',
        },
      };
    },
  },
};
</script>

<style lang="scss">
  .bed-management-page {
  }
</style>
